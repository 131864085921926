import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

const Card = (props) => {
  const {
    title,
    acf: { image: imageObj, description },
    url,
  } = props;

  const image = imageObj?.localFile?.childImageSharp ?? '';

  return (
    <article className="group">
      <Link className="flex flex-col sm:flex-row" to={url}>
        <div className="sm:h-[190px] overflow-hidden sm:w-[338px] flex-shrink-0 sm:mr-6 lg:mr-10">
          <GatsbyImage
            className="h-full w-full"
            imgClassName="rounded-[10px]"
            image={getImage(image)}
            alt={imageObj?.altText}
          />
        </div>
        <div className="flex flex-col justify-between mt-4 sm:mt-0">
          <div>
            <Heading
              className="transition-colors duration-200 xl:!leading-8 group-hover:text-primary-2 overflow-ellipsis sm:line-clamp-3 lg:line-clamp-2"
              tag="h2"
              size="sm"
              fontWeight="bold"
            >
              {title}
            </Heading>
            <p className="text-sm xl:text-xl xl:!leading-7 mt-2 lg:mt-4 overflow-ellipsis md:line-clamp-2">
              {description}
            </p>
          </div>
          <span className="block text-primary-2 font-bold text-sm xl:text-[18px] mt-5 leading-none">
            Listen Now
          </span>
        </div>
      </Link>
    </article>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  acf: PropTypes.shape({
    image: PropTypes.objectOf(PropTypes.any).isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

Card.defaultPropTypes = {
  categories: null,
};

export default Card;
