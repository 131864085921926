import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';

import Card from './card';

const Grid = ({ items }) => (
  <section className="mt-5 md:mt-16">
    <Container>
      <div className="grid grid-cols-12 xl:gap-x-10">
        <div className="relative col-start-1 col-end-13 lg:col-start-2 lg:col-end-12 xl:col-start-3 xl:col-end-11">
          <div className="grid grid-cols-1 gap-y-11 2xl:gap-y-16">
            {items?.map((props, index) => <Card {...props} key={index} />) ?? null}
          </div>
        </div>
      </div>
    </Container>
  </section>
);

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default Grid;
