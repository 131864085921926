/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';

import Pagination from 'components/pages/blog/pagination';
import Grid from 'components/pages/podcasts/grid';
import FeaturedPost from 'components/shared/featured-post';
import Subscribe from 'components/shared/subscribe/subscribe';
import MainLayout from 'layouts/main';

const Podcasts = ({
  data: {
    wpPage: {
      seo,
      uri,
      acf: { podcastHero },
    },
    featuredPodcast,
    allWpPodcast: { nodes: posts },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <FeaturedPost
      featuredPost={featuredPodcast?.nodes[0]}
      withoutCategory
      withoutAuthor
      withListenNowButton
      {...podcastHero}
    />
    <Grid items={posts} />
    {pageContext.pageCount > 1 && (
      <Pagination
        pageCount={pageContext.pageCount}
        currentPageIndex={pageContext.currentPage}
        pageRootPath={uri}
      />
    )}
    <Subscribe />
  </MainLayout>
);

export default Podcasts;

export const query = graphql`
  query ($id: String, $featuredPodcastId: String, $skip: Int, $limit: Int) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        podcastHero {
          title
          description
        }
      }
      ...wpPageSeo
    }
    featuredPodcast: allWpPodcast(filter: { id: { eq: $featuredPodcastId } }) {
      nodes {
        title
        readingTime
        author {
          node {
            name
          }
        }
        acf {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          description
        }
        url: uri
      }
    }
    allWpPodcast(
      filter: { id: { ne: $featuredPodcastId } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        readingTime
        author {
          node {
            name
          }
        }
        acf {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          description
        }
        url: uri
      }
    }
  }
`;
